import { loadable } from '@fiverr-private/perseus';

const DomainExpertsSourcingDrawerButton = loadable(
  () => import(/* webpackChunkName: "DomainExpertsSourcingDrawerButton" */ './DomainExpertsSourcingDrawerButton')
);

export const DOMAIN_EXPERT_SOURCING_DRAWER_BUTTON_CONFIG = {
  components: [
    {
      component: DomainExpertsSourcingDrawerButton,
      options: {
        name: 'Domain Experts Sourcing Drawer Button',
        inputs: [
          {
            name: 'buttonText',
            type: 'text',
            required: true,
            defaultValue: 'Source Freelancers',
          },
        ],
        defaults: {
          bindings: {
            'component.options.currencyObject': 'state.contextData.currencyObject',
          },
        },
      },
    },
  ],
};
